<template>
  <div>
    <div class="card">
      <div class="card-body">
        <form action="">
          <div class="form-row">
            <div class="col-md-4">
              <a-range-picker @change="onDateRangeChange" />
            </div>
            <div class="col-md-4">
              <a-button class="btn btn-success btn-block" :loading="btnLoading" @click.prevent="search()"><i class="fa fa-search mr-1" aria-hidden="true"></i>Search</a-button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-sm table-bordered" id="purchase_order_list">
            <thead>
            <tr>
              <th>#</th>
              <th>Requisition No.</th>
              <th>Zone</th>
              <th>Status</th>
              <th>Created At</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(requisition, i) in requisitions.data" :key="i">
              <td>{{ requisitions.from + i }}</td>
              <td>{{ requisition.requisition_number }}</td>
              <td>{{ requisition.hub.hub_zone.hub_zone_desc }}</td>
              <td>
                <a-tag color="#f50" v-if="(requisition.requisition_status.includes('STORE_IN_CHARGE_PENDING') || requisition.requisition_status.includes('STORE_IN_CHARGE_APPROVED')) && !requisition.requisition_status.includes('ADMIN_APPROVED')">Pending</a-tag>
                <a-tag color="#87d068" v-else-if="requisition.requisition_status.includes('ADMIN_APPROVED') && !requisition.requisition_status.includes('REQUISITION_TRANSFER_RECEIVED_PENDING')">Approved</a-tag>
                <a-tag color="#E0A800" v-else-if="requisition.requisition_status.includes('REQUISITION_TRANSFER_PENDING') && !requisition.requisition_status.includes('REQUISITION_TRANSFER_RECEIVED')">Transfer Accept Pending</a-tag>
                <a-tag color="#87d068" v-else-if="requisition.requisition_status.includes('REQUISITION_TRANSFER_RECEIVED')">Transfer Received</a-tag>
                <a href="javascript: void(0);" v-b-modal.item-requisition-status-modal @click="$bvModal.show('item-requisition-status-modal'), status(requisition.max_item_requisition_status, requisition)" class="btn btn-sm btn-primary">
                  <i class="fe fe-eye"/>
                </a>
              </td>
              <td>{{ customDate(requisition.created_at) }}</td>
              <td>
                <router-link :to="{ name: 'hubManageRequisitionPreviousTransfer', params: { requisition_id: requisition.id } }" class="btn btn-sm btn-success mr-2" tag="a">
                  <i class="fe fe-info"/>  Transfer Details
                </router-link>
                <router-link :to="{ name: 'hubViewRequisition', params: { requisition_id: requisition.id } }" class="btn btn-sm btn-info mr-2" tag="a">
                  <i class="fe fe-eye"/> View
                </router-link>
<!--                <a-button class="btn btn-sm btn-success mr-2" :loading="pdfDownloadLoader && i == index" @click.prevent="pdfDownload(requisition.id, index = i)"><i class="fa fa-file-pdf-o mr-1"></i> Download</a-button>-->
              </td>
            </tr>
            </tbody>
          </table>
          <a-skeleton active :loading="loading"></a-skeleton>
          <h5 class="text-center text-secondary" v-if="requisitions.total === 0 || !flag">
            <Empty :image="simpleImage"></Empty>
          </h5>
          <hr>
          <div class="float-right">
            <pagination class="mt-2" :data="requisitions" :limit="2" @pagination-change-page="getResults"></pagination>
          </div>
        </div>
      </div>
    </div>
    <status :current="current" :requisition="requisition"></status>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import moment from 'moment'
import apiClient from '@/services/axios'
import Status from '@/views/requisition/partial/status'

export default {
  name: 'List',
  components: { Empty, Status },
  data() {
    return {
      requisitions: {},
      requisition: {},
      loading: false,
      btnLoading: false,
      flag: false,
      pdfDownloadLoader: false,
      index: -1,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      current: 0,
      search_data: {
        date_range: '',
      },
    }
  },
  mounted() { },
  methods: {
    customDate(date) {
      return moment(date).format('LL')
    },
    onDateRangeChange(date, dateString) {
      this.search_data.date_range = dateString
    },
    search() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.flag = true
          this.loading = true
          this.btnLoading = true
          apiClient.post('api/hub/requisition-previous-transfer/search', this.search_data)
            .then(response => {
              this.loading = false
              this.btnLoading = false
              this.flag = true
              this.requisitions = response.data.requisitions
            })
            .catch(error => {
              console.log(error)
            })
        }
      })
    },
    getResults(page = 1) {
      this.$validator.validate().then(valid => {
        if (valid) {
          apiClient.post('api/hub/requisition-previous-transfer/search?page=' + page, this.search_data)
            .then(response => {
              this.requisitions = response.data.requisitions
            })
        }
      })
    },
    pdfDownload(requisitionId, index) {
      this.pdfDownloadLoader = true
      apiClient.get('api/requisition/pdf-download/' + requisitionId, { responseType: 'blob' }).then(response => {
        this.pdfDownloadLoader = false
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'requisition.pdf')
        document.body.appendChild(link)
        link.click()
      }).catch(error => {
        console.log(error)
      })
    },
    status(itemRequisitionStatus, requisition) {
      this.current = 0
      this.requisition = ''
      this.current = itemRequisitionStatus
      this.requisition = requisition
    },
  },
}
</script>

<style scoped>
.form-control-sm {
  height: calc(1.7em + .5rem + 2px) !important;
}
</style>
